import React, { useState, useEffect } from 'react';
import { useNavigate }                from 'react-router-dom';
import {jwtDecode}                    from "jwt-decode";

import api                            from "../Utils/Axios";
import Header                         from '../Components/Header';
import Sidebar                        from '../Components/Sidebar';
import { BACKEND_URL }                from '../Utils/constant';

const ProfilePage = () => {
    const [token, setToken] = useState(null);
    const [expire, setExpire] = useState('');
    const navigate = useNavigate();

    const [firstName] = useState(() => {
        return localStorage.getItem("firstName");
    });
    const [lastName] = useState(() => {
        return localStorage.getItem("lastName");
    });
    const [email] = useState(() => {
        return localStorage.getItem("email");
    });
    useEffect(() => {
        if(!localStorage.getItem('token')) getToken();
    });
    const [stt, setStt] = useState(true);
    function funcChange(){
        console.log("change");
        //setStt(!stt);
    }
  
    const getToken = async () => {
        try {
            const response = await api.get(BACKEND_URL+'/token');
            setToken(response.data.accessToken);
            localStorage.setItem('token', response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            setExpire(decoded.exp);
        } catch (error) {
            console.log(error);
            if (error.response) {
                navigate("/login");
            }
        }
    }

    return (<> 
                <Header/>
                <Sidebar/>
                <div className="profileSection">
                    <div className="row justify-content-md-center">
                        <div className="col col-lg-2">
                            <label htmlFor="firstName" className="form-label">First name<span className="text-info"></span></label>
                        </div>
                        <div className="col-md-3">
                            <input type="text" className="form-control" id="firstName" value={firstName} disabled/>
                        </div>
                    </div>
                    <br/>
                    <div className="row justify-content-md-center">
                        <div className="col col-lg-2">
                            <label htmlFor="lastName" className="form-label">Last name<span className="text-info"></span></label>
                        </div>
                        <div className="col-md-3">
                            <input type="text" className="form-control" id="lastName" value={lastName} disabled/>
                        </div>
                    </div>
                    <br/>
                    <div className="row justify-content-md-center">
                        <div className="col col-lg-2">
                            <label htmlFor="email" className="form-label">Email<span className="text-info"></span></label>
                        </div>
                        <div className="col-md-3">
                            <input type="text" className="form-control" id="email" value={email} disabled/>
                        </div>
                    </div>
                    <br/>
                    <div className="row justify-content-md-center">
                        <div className="col col-lg-2">
                            <label htmlFor="password" className="form-label">Password<span className="text-info"></span></label>
                        </div>
                        <div className="col-md-3">
                            <input type="password" className="form-control" id="password" value="************" disabled/>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <div className="row justify-content-md-center">
                        <a className="btn btn-primary col-md-auto" href="/">
                            <span className="material-icons fs-6 pe-1">home</span>Return
                        </a>
                    </div>

                </div>
            </>

    );
};
export default ProfilePage;