import AppRouter from './Router/AppRouter';
import "./App.css";

function App() {
  return (

    <div className="App">
        <AppRouter/>

    </div>

 
  );
}

export default App;
