// store.js
import { configureStore } from "@reduxjs/toolkit";

const FLEET_DISPLAY = "fleetDisplay";

const initialState = {
  fleetDisplay: false,
};

export const setFleetDisplay = (fleetDisplay) => ({
  type: FLEET_DISPLAY,
  payload: fleetDisplay,
});

export const rootReducer  = (state = initialState, action) => {
  switch(action.type){
    case FLEET_DISPLAY: 
      return {
        ...state,
        [action.type]: action.payload,
      };
    default:
      return state;
    }
};

export const store = configureStore({
  reducer: rootReducer
});
