import React           from 'react';

import api             from "../Utils/Axios";
import { BACKEND_URL } from '../Utils/constant';

export default function TileOverlay(props) {
  const {tileId, tileVersion, tileSize, tileDate, tileLevel, tileExtension, tileFilePath } = props;
  const displayMetadata = (tileDate)? true : false;

  function convertSize(){
    if(tileSize < 1000)                         return tileSize + ' B';
    if(tileSize >= 1000 && tileSize < 1000000)  return (tileSize/1000).toFixed(2) + ' KB';
    else                                        return (tileSize/1000000).toFixed(2) + ' MB';
  }

  const downloadTile = async(e) => {
    e.preventDefault();
    let params = "file_name=" + tileId + "." + tileExtension + "&object_key=" + tileFilePath;
    let completeUrl = BACKEND_URL + '/download?' + params;
    try {
      let res = await api.get(completeUrl,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (res.data.presigned_url) window.open(res.data.presigned_url);
      else  alert(res.data.message);
    }
    catch(error){
      console.log(error);
    }
  }
  
  return ( 
      <div className="map-overlay top">
        <div className="map-overlay-inner">
        {displayMetadata ?
          <fieldset>
              <label htmlFor="easing"><b>Selected Layer Tile</b></label><br/>
              &emsp;<label htmlFor="easing"><b>Tile ID: </b>{tileId}</label><br/>
              &emsp;<label htmlFor="easing"><b>Tile Version: </b>{tileVersion}</label><br/>
              &emsp;<label htmlFor="easing"><b>Tile Size:  </b>{convertSize()}</label><br/>
              &emsp;<label htmlFor="easing"><b>Tile Date: </b>{tileDate}</label><br/>
              &emsp;<label htmlFor="easing"><b>Tile Level: </b>{tileLevel}</label><br/>
              &emsp;<button onClick={downloadTile}><b>Download Tile</b></button>
          </fieldset> : 
          <fieldset>
              <label htmlFor="easing"><b>Selected Data Tile</b></label><br/>
              &emsp;<label htmlFor="easing"><b>Tile ID: </b>{tileId}</label>
          </fieldset>
        }
        </div>
    </div>
  );
}