import React, { useState }        from 'react';
import { useNavigate }            from 'react-router-dom';

import { ReactComponent as Logo}  from '../drive4u_logo.svg';
import api                        from "../Utils/Axios";
import { BACKEND_URL }            from '../Utils/constant';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [msg, setMsg] = useState('');
  const navigate = useNavigate();

  const Auth = async (e) => {
    e.preventDefault();
    try {
        let res = await api.post(BACKEND_URL+'/login', {
            email: email,
            password: password
            },
            {
                credentials: "include",
                withCredentials: "true"
            });
        localStorage.setItem("userId",res.data.userId);
        localStorage.setItem("token",res.data.accessToken);
        localStorage.setItem("email",email);
        if(res.status === 202) navigate("/reset");
        else navigate("/");
    } catch (error) {
        if (error.response) {
            setMsg(error.response.data.msg);
        }
    }
  }

  const handlePasswordToggle = () => {
    const passwordInput = document.getElementById('password');
    const passwordVisibleIcon = document.querySelector('.password-visible');

    if (passwordInput.getAttribute('type') === 'password') {
      passwordInput.setAttribute('type', 'text');
      passwordVisibleIcon.textContent = 'visibility_off';
    } else {
      passwordInput.setAttribute('type', 'password');
      passwordVisibleIcon.textContent = 'visibility';
    }
  };

  return (
    <div className="authentication-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-md-4 p-0 mx-auto">
            <div className="p-4 shadow bg-white">
              <div className="text-center">
                <Logo width={180} />
              </div>
              <form onSubmit={Auth}>
                <p className="text-danger has-text-centered">{msg}</p>
                <div className="mb-4">
                  <label className="form-label">
                    Email <span className="text-info">*</span>
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="form-control  rounded-0"
                    placeholder="i.e username@mail.com"
                    value={email || ''}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="mb-2 position-relative">
                  <label className="form-label">
                    Password <span className="text-info">*</span>
                  </label>
                  <input
                    type="password"
                    id="password"
                    placeholder="************"
                    className="form-control rounded-0"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <span
                    className="material-icons password-visible cursor-pointer"
                    onClick={handlePasswordToggle}
                  >
                    visibility
                  </span>
                </div>

                <div className="text-center pt-1 pb-1">
                  <button className="btn btn-primary w-100 mb-3" disabled={email.trim() === '' || password.trim() === '' || password.length < 12} type="submit">
                    SIGN IN
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
