import React                                      from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage                                   from '../Pages/HomePage';
import LoginPage                                  from '../Pages/LoginPage';
import ResetPage                                  from '../Pages/ResetPage';
import ProfilePage                                from '../Pages/ProfilePage';
import NotFoundPage                               from '../Pages/NotFoundPage';
import LayersPage                                 from '../Pages/LayersPage';
import LayerPage                                  from '../Pages/LayerPage';
import FleetsPage                                 from '../Pages/FleetsPage';
import FleetPage                                  from '../Pages/FleetPage';

const AppRouter = () => (
  <Router>

    <Routes>
      <Route path="/login"              element={<LoginPage />} />
      <Route path="/reset"              element={<ResetPage />} />
      <Route path="/profile"            element={<ProfilePage/>} />
      <Route path="/layers"             element={<LayersPage/>} />
      <Route path="/layers/:layerId"    element={<LayerPage/>} />
      <Route path="/fleets"             element={<FleetsPage/>} />
      <Route path="/fleets/:fleetId"    element={<FleetPage/>} />
      <Route path="/"                   element={<HomePage/>} />
      <Route path="/:layerId"           element={<HomePage coloring={0}/>} />
      <Route path="/:layerId/byversion" element={<HomePage coloring={1}/>} />
      <Route path="/:layerId/bysize"    element={<HomePage coloring={2}/>} />
      <Route path="/:layerId/bydate"    element={<HomePage coloring={3}/>} />
      <Route path="*"                   element={<NotFoundPage />} />
    </Routes>
  </Router>
);

export default AppRouter;
