/* global BigInt */
import { TILE_LEVEL } from "./constant";

function getX(lng){
    let llh2tc = (lng + 180.0) / 360.0 * Math.pow(2,18);

    return BigInt(Math.floor(llh2tc));
}

function getY(lat){
    let llh2tc = (lat + 90.0) / 360.0 * Math.pow(2,18);

    return BigInt(Math.floor(llh2tc));
}

function part1By1(val) {
    let bigVal = BigInt(val);

    bigVal = (bigVal ^ (bigVal << 16n)) & 0x0000ffff0000ffffn;
    bigVal = (bigVal ^ (bigVal << 8n))  & 0x00ff00ff00ff00ffn;
    bigVal = (bigVal ^ (bigVal << 4n))  & 0x0f0f0f0f0f0f0f0fn;
    bigVal = (bigVal ^ (bigVal << 2n))  & 0x3333333333333333n;
    bigVal = (bigVal ^ (bigVal << 1n))  & 0x5555555555555555n;

    return bigVal
}

function coordinates2PointCloud(lat, lng) {
    let tileX = getX(lng);
    let tileY = getY(lat);

    let mortonVal   = BigInt((part1By1(tileY) << BigInt(1)) + part1By1(tileX));
    mortonVal       = mortonVal | BigInt(Math.pow(2,TILE_LEVEL*2));

    return Number(mortonVal);
}

/*module.exports = {
    encodeMorton,
    part1By1,
    getY,
    getX
};*/
export default coordinates2PointCloud;
