import React, {useState, useEffect} from "react";
import { useNavigate }              from 'react-router-dom';
import { useLocation }              from 'react-router-dom';

import { ReactComponent as Logo }   from '../user-avatar.svg';
import api                          from '../Utils/Axios';
import { BACKEND_URL }              from '../Utils/constant';

const Header = () => {
    const location = useLocation();
    const [selectedLayer, setSelectedLayer] = useState("");
    const navigate = useNavigate();
    const Profile = async () => {
        navigate("/profile");
    }
    const Layers = async () => {
        navigate("/layers");
    }
    const Fleets = async () => {
        navigate("/fleets");
    }
    useEffect(() => {
        getLayerName();
    },[]);
    const getLayerName = async () => {
        const path  = location.pathname;
        const id    = parseInt(path[1]);

        if(Number.isInteger(id)){
            try {
                const response = await api.get(BACKEND_URL+'/layer?id='+id,{
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                if (response.data){
                    const layer = response.data.name;
                    setSelectedLayer(layer);
                }
            } catch (error) {
                console.log(error);
            }
        }
        else setSelectedLayer('');
    }

    const SignOut = async () => {
        try {
            await api.delete('/logout');
            localStorage.setItem("token", "");
            navigate("/login");
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <header className="py-2 bg-white shadow-sm fixed-top">
        <div className="container-fluid">
            <div className="d-flex flex-wrap align-items-end justify-content-center justify-content-start">
            <div className="d-flex align-items-end mb-0 me-auto">
                <a className="navbar-brand me-auto py-0" href="/">
                <img
                    className="d-md-inline"
                    alt="Valeo logo"
                    src="https://storage.googleapis.com/valeo-cp2096.appspot.com/img/valeo-green-logo.png"
                />
                </a>
            </div>
            <div className="d-flex me-auto rounded selectedLayer">
                <b>{selectedLayer}</b>
            </div>
            <div className="p-2 bg-white shadow-sm rounded">
                <a
                    href="#"
                    className="d-block link-dark text-decoration-none dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    <Logo width={30} height={30} className="rounded-circle z-depth-0" alt="avatar image"/>
                </a>
                <ul className="dropdown-menu text-small">
                    <li>
                        <a className="dropdown-item" onClick={Profile}>Profile</a>
                    </li>
                    <li>
                        <a className="dropdown-item" onClick={Layers}>Layer Manager</a>
                    </li>
                    <li>
                        <a className="dropdown-item" onClick={Fleets}>Fleet Manager</a>
                    </li>
                    <li>
                        <hr className="dropdown-divider" />
                    </li>
                    <li>
                        <a className="dropdown-item" onClick={SignOut}>Sign out</a>
                    </li>
                </ul>
            </div>
            </div>
        </div>
        </header>
    );
};

export default React.memo(Header);
