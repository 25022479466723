import React, { useState, useEffect } from 'react';
import { useParams  }                 from 'react-router-dom';

import api                            from "../Utils/Axios";
import Header                         from '../Components/Header';
import Sidebar                        from '../Components/Sidebar';
import { BACKEND_URL }                from '../Utils/constant';

const FleetPage = () => {
    const {fleetId} = useParams();
    const initialMetadata = {id: 0, description:"", image_name: "valeo-card.png", name: "", state: true, url: "", subscription: null};
    const [fleet, setFleet] = useState(initialMetadata);
    const getData = async () => {
        try {
            const response = await api.get(BACKEND_URL+'/fleet?id='+fleetId,{
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            if(response.data) setFleet(response.data);
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        getData();
      }, []);
    return (<>
                <Header/>
                <Sidebar/>
                <div className="LayerSection">
                    <div className="card shadow-md cardlayer">
                        <div className="card-header d-flex align-items-top bg-white">
                            <div className="img-rounded me-2">
                                <img src={require('./'+fleet.image_name)} alt="..." />
                            </div>
                            <div>
                                <div>
                                    <h6 className="m-2">{fleet ? fleet.name: null}</h6>
                                    <p className="m-2">{fleet ? fleet.description: null}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-md-center">
                        <a className="btn btn-primary col-md-auto returnButton" href="/fleets">
                            <span className="material-icons">keyboard_return</span>
                        </a>
                    </div>
                </div>
            </>

    );
};
export default FleetPage;