import React, { useState, useEffect }   from 'react';
import { useNavigate, useParams  }      from 'react-router-dom';
import {jwtDecode}                      from "jwt-decode";

import api                              from "../Utils/Axios";
import Header                           from '../Components/Header';
import Sidebar                          from '../Components/Sidebar';
import Map                              from '../Components/Map';
import { BACKEND_URL }                  from '../Utils/constant';

const HomePage = (prop) => {
    const {layerId = 0} = useParams();
    const {coloring} = prop;
    const [token, setToken] = useState(null);
    const [expire, setExpire] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        getToken();
    }, []);

    const getToken = async () => {
        try {
            const response = await api.get(BACKEND_URL+'/token');
            setToken(response.data.accessToken);
            localStorage.setItem('token', response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            localStorage.setItem('firstName', decoded.firstName);
            localStorage.setItem('lastName', decoded.lastName);  
            setExpire(decoded.exp);
        } catch (error) {
            console.log(error);
            if (error.response) {
                navigate("/login");
            }
        }
    }

    const axiosJWT = api.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await api.get(BACKEND_URL+'/token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            config.credentials = "include";
            config.withCredentials = "true";
            setToken(response.data.accessToken);
            localStorage.setItem('token', response.data.accessToken);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });


    return (<>
        <Header/>
        <Sidebar/>
        <Map    layer = {layerId}
                coloring = {coloring}
        /></>
    );
};

export default HomePage;