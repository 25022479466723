import React from 'react';

const NotFoundPage = () => {
  return (
    <div className="error-section container-404">
  <div className="text-center">
    <div className="icon-404">
      <div className="ghost-copy">
        <div className="one"></div>
        <div className="two"></div>
        <div className="three"></div>
        <div className="four"></div>
      </div>
      <div className="ghost">
        <div className="face">
          <div className="eye"></div>
          <div className="eye-right"></div>
          <div className="mouth"></div>
        </div>
      </div>
      <div className="shadow"></div>
    </div>
    <h1 className="text-404">404 </h1>
    <h3 className="text-not-found">
      Page not found <br /> The page you were looking for doesn't exists.
    </h3>
    <div className="row justify-content-md-center">
      <a className="btn btn-primary col-md-auto" href="/">
        <span className="material-icons fs-6 pe-1">home</span>Return
      </a>
    </div>
  </div>
</div>

  );
};

export default NotFoundPage;