import React, { useRef, useEffect, useState}  from 'react';
import maplibregl                             from 'maplibre-gl';
import MaplibreGeocoder                       from '@maplibre/maplibre-gl-geocoder';
import PointCloudLayer                        from "./PointCloudLayer";
import FleetsContainer                        from "./FleetsContainer";
import { ToastContainer, toast }              from "react-toastify";
import coordinates2PointCloud                 from '../Utils/MortonEncode';

import 'maplibre-gl/dist/maplibre-gl.css';
import "react-toastify/dist/ReactToastify.css";
import "./map.css";

/* global BigInt */

export default function Map(prop) {
  var {layer, coloring} = prop;
  const [map, setMap] = useState(useRef(null));
  const mapContainer = useRef(null);
  //const map = useRef(null);
  const [lng] = useState(2.3389);
  const [lat] = useState(48.8654);
  const [zoom] = useState(12);
  const [MAP_API_KEY] = useState('IkNH6IBiP2Dt053pntcX');
  let popup = new maplibregl.Popup();

  function renderChoice(param){
    switch (param){
      case 0: return(null);
      default: return (
                <PointCloudLayer
                  layer = {layer}
                  map = {map}
                  coloring = {coloring}
                />
              );
    }
  }
  // Search bar API
  const geocoderApi = {
    forwardGeocode: async (config) => {
      const features = [];
      try {
          const request = `https://nominatim.openstreetmap.org/search?q=${config.query}&format=geojson&polygon_geojson=1&addressdetails=1`;
          const response = await fetch(request);
          const geojson = await response.json();
          for (const feature of geojson.features) {
              const center = [
                  feature.bbox[0] +
              (feature.bbox[2] - feature.bbox[0]) / 2,
                  feature.bbox[1] +
              (feature.bbox[3] - feature.bbox[1]) / 2
              ];
              const point = {
                  type: 'Feature',
                  geometry: {
                      type: 'Point',
                      coordinates: center
                  },
                  place_name: feature.properties.display_name,
                  properties: feature.properties,
                  text: feature.properties.display_name,
                  place_type: ['place'],
                  center
              };
              features.push(point);
          }
      } catch (e) {
          console.error(`Failed to forwardGeocode with error: ${e}`);
      }

      return {
          features
      };
    }
  };

  useEffect(() => {
    if (map.current) return; // Stops map from intializing more than once
    
    map.current = new maplibregl.Map({
      container: mapContainer.current,
      style: `https://api.maptiler.com/maps/streets-v2/style.json?key=${MAP_API_KEY}`,
      center: [lng, lat],
      zoom: zoom
    });
    map.current.addControl(new maplibregl.NavigationControl(), 'top-right');                                                  // Zoom-in/Zoom-out buttons
    map.current.addControl(new maplibregl.GeolocateControl({positionOptions: {enableHighAccuracy: true}}));                   // Position button
    map.current.addControl(new MaplibreGeocoder(geocoderApi, {language: 'en-EN', marker: false,},{maplibregl}), "top-left");  // Search bar()
    map.current.addControl(new maplibregl.ScaleControl({maxWidth: 80, unit: 'metric'}));                                      // Map Scale Control

    // Manage click-right event
    map.current.on('contextmenu', function(e) {
      let lng = e.lngLat.lng;
      let lat = e.lngLat.lat;
      let tileId = coordinates2PointCloud(lat, lng);

      let coord = {lng: lng, lat: lat};

      // Display Tile ID
      let tileID = "Tile ID: " + tileId;
      const buttonTile = document.createElement('div');
      buttonTile.innerHTML = `<button className="withoutBorder">`+tileID+`</button>`;
      buttonTile.addEventListener('click', (e) => {
        navigator.clipboard.writeText(tileId.toString());
        toast("Copied to clipboard");
      });
      // Display Tile Coordinates
      let coordinates = lat.toFixed(5)+', ' + lng.toFixed(5);
      let coordinatesSTR = JSON.stringify(coordinates);
      coordinatesSTR = coordinatesSTR.replace('"','');
      coordinatesSTR = coordinatesSTR.replace('"','');
      const buttonCoordinates = document.createElement('div');
      buttonCoordinates.innerHTML = `<button className="withoutBorder">`+coordinatesSTR+`</button>`;
      buttonCoordinates.addEventListener('click', (e) => {
        navigator.clipboard.writeText(coordinates);
        toast("Copied to clipboard");
      });
      const br = document.createElement('hr');
      const description = document.createDocumentFragment();
      description.appendChild(buttonTile);
      description.appendChild(br);
      description.appendChild(buttonCoordinates);
      popup.remove();
      popup = new maplibregl.Popup({closeOnClick: true})
        .setLngLat(coord)
        .setDOMContent(description)
        .addTo(map.current);
    });
  }, [MAP_API_KEY, lng, lat, zoom]);

  return (
    <div className="map-wrap">
      <ToastContainer 
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div ref={mapContainer} className="map" >
      </div>
      <div>
        {renderChoice(layer)}
      </div>
      <div>
        <FleetsContainer 
          map = {map}
        />
      </div>
    </div>
  );

}