import React, { useEffect, useState } from 'react';
import { useDispatch }                from 'react-redux';
import { useLocation }                from 'react-router-dom';

import api                            from "../Utils/Axios";
import { BACKEND_URL }                from '../Utils/constant';
import { setFleetDisplay }            from "../Redux/store";

const Sidebar = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const layers = (JSON.parse(localStorage.getItem("subscribedLayers"))) ? (JSON.parse(localStorage.getItem("subscribedLayers"))):[];
  const [selectedfleets, setSelectedFleets] = useState(() => {
    return JSON.parse(localStorage.getItem('subscribedFleets'));
  });
  const [userId] = useState(() => {
    return localStorage.getItem("userId");
  });
  const [token] = useState(() => {
    return localStorage.getItem("token");
  });
  const isChecked = (localStorage.getItem('subscribedFleets')) ? JSON.parse(localStorage.getItem('subscribedFleets'))[0].checked : false;
  const getSubscriptionLayers = async (e) => {
    try {
        let res = await api.get(BACKEND_URL+`/layersubscriptions?id=${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        localStorage.setItem("subscribedLayers", JSON.stringify(res.data));
    } catch (error) {
      console.log(error);
    }
  };

  const getSubscriptionFleets = async (e) => {
    try {
        let res = await api.get(BACKEND_URL+`/fleetsubscriptions?id=${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        localStorage.setItem("subscribedFleets", JSON.stringify(res.data));
        setSelectedFleets(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(setFleetDisplay(isChecked));
    if(localStorage.getItem("subscribedLayers") === null) getSubscriptionLayers();
    if(localStorage.getItem("subscribedFleets") === null) getSubscriptionFleets();
  }, []);

  function handleFilter(filter){
    const path = location.pathname;
    if(path.length == 2) return path + filter;
    else{
      const id = parseInt(path[1]);
      if(Number.isInteger(id))  return '/' + id + filter;
      else                      return '/';
    }
  }

  const handleOnChange = (event) => {
    const data = JSON.parse(localStorage.getItem("subscribedFleets"));
    dispatch(setFleetDisplay(event.target.checked));
    data.map((object) => { 
      if(object.id == event.target.id) object.checked = event.target.checked;
      return {...object};
    });
    localStorage.setItem("subscribedFleets",JSON.stringify(data));
    setSelectedFleets(JSON.parse(localStorage.getItem('subscribedFleets')));
  };

  useEffect(() => {
      const sidebarWrapper = document.getElementById('sidebar-wrapper');

      sidebarWrapper.addEventListener('mouseenter', () => {
        sidebarWrapper.classList.remove('sidebar-closed');
        const collapseActive = sidebarWrapper.querySelector('[data-bs-toggle="collapse"].active');
        if (collapseActive) {
          collapseActive.nextElementSibling.classList.add('show');
        }
      });

      sidebarWrapper.addEventListener('mouseleave', () => {
        sidebarWrapper.classList.add('sidebar-closed');
        const collapses = sidebarWrapper.querySelectorAll('.collapse');
        collapses.forEach((collapse) => {
          collapse.classList.remove('show');
        });
      });
  }, []);

  const sidebarToggler = () => {
      const sidebarWrapper = document.getElementById('sidebar-wrapper');
  
      if (sidebarWrapper.classList.contains('sidebar-closed')) {
        sidebarWrapper.classList.remove('sidebar-closed');
        const collapseActive = sidebarWrapper.querySelector('[data-bs-toggle="collapse"].active');
        if (collapseActive) {
          collapseActive.nextElementSibling.classList.add('show');
        }
      } else {
        sidebarWrapper.classList.add('sidebar-closed');
        const collapses = sidebarWrapper.querySelectorAll('.collapse');
        collapses.forEach((collapse) => {
          collapse.classList.remove('show');
        });
      }
  };

  return (
      <>
      {/* Sidebar Starts */}
      <div id="sidebar-page">
          <div className="d-flex align-items-center" style={{margin: "15px"}}>
              <a className="material-icons text-decoration-none text-dark cursor-pointer" id="mainMenulink" onClick={sidebarToggler}>menu</a>
          </div>
          <aside className="sidebar sidebar-closed bg-primary" id="sidebar-wrapper">
          {/* Sidebar content */}
              <ul className="list-unstyled">
                <li className="nav-item">
                    <a className="" href="/">
                    <span className="material-icons"> home</span> 
                    <span className="sidebar-label"> Home </span>
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="collapse" data-bs-target="#layerdrop" id="layerNavLink">
                    <span className="material-icons"> layers </span>
                    <span className="sidebar-label"> Layer </span>
                    <span className="d-block ms-auto">
                        <i className="material-icons"> chevron_right </i>
                    </span>
                    </a>
                    <ul className="submenu collapse" id="layerdrop" data-bs-parent="#sidebar-wrapper">
                      {(layers.length > 0) ? (layers.map((object) => 
                        <li className="nav-item" key={object.layer.name}>
                          <a className="nav-link" id="alertNavLink" href={'/'+object.layer.id}> {object.layer.name} </a>
                        </li>
                      )) : (
                        <li className="nav-item" key="nothing">
                          <a className="nav-link disabled" id="alertNavLink" href="#"> No subscribed layers </a>
                        </li>
                      )}
                    </ul>
                </li>
                {(layers.length > 0) ? (
                <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="collapse" data-bs-target="#colordrop" id="colorNavLink">
                    <span className="material-icons"> color_lens </span>
                    <span className="sidebar-label"> Color </span>
                    <span className="d-block ms-auto">
                        <i className="material-icons"> chevron_right </i>
                    </span>
                    </a>

                    <ul className="submenu collapse" id="colordrop" data-bs-parent="#sidebar-wrapper">
                      <li className="nav-item">
                          <a className="nav-link" id="alertNavLink" href={handleFilter('')}> None </a>
                      </li>
                      <li className="nav-item">
                          <a className="nav-link" id="accordionNavLink" href={handleFilter('/byversion')}> By Version </a>
                      </li>
                      <li className="nav-item">
                          <a className="nav-link" id="accordionNavLink" href={handleFilter('/bysize')}> By Data Size </a>
                      </li>
                      <li className="nav-item">
                          <a className="nav-link" id="accordionNavLink" href={handleFilter('/bydate')}> By Date </a>
                      </li>
                    </ul>

                </li>) : (null)}

                <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="collapse" data-bs-target="#fleetdrop" id="colorNavLink">
                    <span className="material-icons"> directions_car </span>
                    <span className="sidebar-label"> Fleet </span>
                    <span className="d-block ms-auto">
                        <i className="material-icons"> chevron_right </i>
                    </span>
                    </a>
                    <ul className="submenu collapse" id="fleetdrop" data-bs-parent="#sidebar-wrapper">
                      <div className="row fleetDiv">
                      {(selectedfleets?.length > 0) ? (selectedfleets.map((object) => 
                        <li className="nav-item" key={object.name}>
                          <a className="nav-link aFleet" id="alertNavLink">
                            <div className="form-switch">
                              <div className="col">
                                <label className="form-check-label" htmlFor="flexRadioDefault2">{object.name} </label>
                              </div>
                              <div className="col col-md-2">
                                <input className="form-check-input success-switch" type="checkbox" name="flexRadioDefault" id={object.id} onChange={handleOnChange} checked={object.checked}/>
                              </div>
                              
                            </div>
                          </a>
                        </li>
                      )) : (
                        <li className="nav-item" key="nothing">
                          <a className="nav-link aFleet disabled" id="alertNavLink" href="#"> No subscribed fleets</a>
                        </li>
                      )}
                      </div>
                    </ul>
                </li>
              </ul>
          </aside>
      </div>
      </>
  );
};

export default React.memo(Sidebar);
