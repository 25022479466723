import React, { useState, useEffect } from 'react';

import api                            from "../Utils/Axios";
import Header                         from '../Components/Header';
import Sidebar                        from '../Components/Sidebar';
import { BACKEND_URL }                from '../Utils/constant';
import "./layers.css";

const LayersPage = () => {
  const [layers, setLayers] = useState([]);
  const [token] = useState(() => {
    return localStorage.getItem("token");
  });
  const [userId] = useState(() => {
    return localStorage.getItem("userId");
  });

  function getStatus(value){
    switch(value){
      case true: return 'Subscribed';
      case false: return 'Pending';
      default: return 'Not subscribed';
    }
  }

  const getLayers = async (e) => {
    try {
        let res = await api.get(BACKEND_URL+`/layers?userId=`+userId, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setLayers(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getLayers();
  }, [])

  return (<> 
            <Header/>
            <Sidebar/>
            <div className="layerSection">
            {(layers.length > 0) ? (layers.map((object) =>
              <div className="card shadow-md cardlayer" key={object.id}>
                <div className="card-header d-flex align-items-top bg-white">
                  <div className="img-rounded me-2">
                  <a href={'/layers/'+object.id}><img src={require('./'+object.image_name)} alt="..." /></a>
                  </div>
                  <div className="fleetDescription">
                    <div className="fleetText">
                        <a className="linkLayer" href={'/layers/'+object.id}><h6 className="m-2">{object.name}</h6></a>
                        <p className="m-2">{object.description}</p>
                      </div>
                      <div className= "bottomCard">
                        {object.subscription ?
                        <a className="btn btn-default categoryButton">{getStatus(object.subscription.status)}</a> :
                        <a className="btn btn-default categoryButton">Not subscribed</a>}
                      </div>
                    </div>
                </div>
              </div>
            )) : null
            }
            </div>
        </>
    );
};
export default LayersPage;