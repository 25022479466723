import React, { useState }          from 'react'
import { useNavigate }              from 'react-router-dom';

import { ReactComponent as Logo}    from '../maps4u.svg';
import api                          from "../Utils/Axios";
import { BACKEND_URL }              from '../Utils/constant';

const ResetPage = () => {
    const [password, setPassword] = useState('');
    const [confPassword, setConfPassword] = useState('');
    const [msg, setMsg] = useState('Your password has expired and must be changed.');
    const [errorMsg, setErrorMsg] = useState('');
    const navigate = useNavigate();

    const Reset = async (e) => {
        e.preventDefault();
        let un = localStorage.getItem("email");
        try {
            if((password.length < 12)||(confPassword.length < 12)) setErrorMsg("Password must respect 12 characters !");
            else if(password.length !== confPassword) setErrorMsg("Password must respect 12 characters !");
            await api.post(BACKEND_URL+'/reset', {
                email: un,
                password: password,
                confPassword: confPassword
                },
                {
                    credentials: "include",
                    withCredentials: "true"
                });
                
            navigate("/");
        } catch (error) {
            if (error.response) {
                setErrorMsg(error.response.data.msg);
            }
        }
    }

    const handlePasswordToggle = () => {
        const passwordInput = document.getElementById('password');
        const passwordVisibleIcon = document.getElementById('spanPassword');
    
        if (passwordInput.getAttribute('type') === 'password') {
          passwordInput.setAttribute('type', 'text');
          passwordVisibleIcon.textContent = 'visibility_off';
          
        } else {
          passwordInput.setAttribute('type', 'password');
          passwordVisibleIcon.textContent = 'visibility';
        }
        
      };
    const handleConfPasswordToggle = () => {
        const confPasswordInput = document.getElementById('confPassword');
        const confPasswordVisibleIcon = document.getElementById('spanConfPassword');

        if (confPasswordInput.getAttribute('type') === 'password') {
            confPasswordInput.setAttribute('type', 'text');
            confPasswordVisibleIcon.textContent = 'visibility_off';
            
        } else {
            confPasswordInput.setAttribute('type', 'password');
            confPasswordVisibleIcon.textContent = 'visibility';
        }
    
    };

    return (
        <div className="authentication-wrapper">
            <div className="container">
                <div className="row">
                <div className="col-md-4 p-0 mx-auto">
                    <div className="p-4 shadow bg-white">
                    <div className="text-center">
                    <Logo width={180} />
                    </div>
                    <form onSubmit={Reset}>
                        <p className="text-secondary has-text-centered">{msg}</p>
                        <p className="text-danger has-text-centered">{errorMsg}</p>
                        <div className="mb-2 position-relative">
                            <label className="form-label">New password <span className="text-info">*</span>
                            </label>
                            <input
                                type="password"
                                id="password"
                                className="form-control  rounded-0"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <span
                                className="material-icons password-visible cursor-pointer"
                                id="spanPassword"
                                onClick={handlePasswordToggle}
                            >
                                visibility
                            </span>
                        </div>
                        <div className="mb-2 position-relative">
                            <label className="form-label">Confirm new password <span className="text-info">*</span>
                            </label>
                            <input
                                type="password"
                                id="confPassword"
                                className="form-control rounded-0"
                                value={confPassword}
                                onChange={(e) => setConfPassword(e.target.value)}
                            />
                            <span
                                className="material-icons password-visible cursor-pointer"
                                id="spanConfPassword"
                                onClick={handleConfPasswordToggle}
                            >
                                visibility
                            </span>
                        </div>

                        <div className="text-center pt-1 pb-1">
                        <button className="btn btn-success w-100 mb-3" disabled={password.length < 12 || confPassword.length < 12} type="submit">
                            Reset Password
                        </button>
                        </div>
                    </form>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPage
