import React, { useState, useEffect } from 'react';

import api                            from "../Utils/Axios";
import Header                         from '../Components/Header';
import Sidebar                        from '../Components/Sidebar';
import { BACKEND_URL }                from '../Utils/constant';
import "./layers.css";

const FleetsPage = () => {
  const AUTHORIZED_DESCRIPTION_LENGTH = 315;
  const [fleets, setFleets] = useState([]);
  const [token] = useState(() => {
    return localStorage.getItem("token");
  });
  const [userId] = useState(() => {
    return localStorage.getItem("userId");
  });

  function getStatus(value){
    switch(value){
      case true: return 'Subscribed';
      case false: return 'Pending';
      default: return 'Not subscribed';
    }
  }

  const getFleets = async (e) => {
    try {
        let res = await api.get(BACKEND_URL+`/fleets?userId=`+userId, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setFleets(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getFleets();
  }, [])

  return (<> 
            <Header/>
            <Sidebar/>
            <div className="layerSection">
            {(fleets.length > 0) ? (fleets.map((object) =>
              <div className="card shadow-md cardlayer" key={object.id}>
                <div className="card-header d-flex align-items-top bg-white">
                  <div className="fleetImage img-rounded me-2">
                    <a href={'/fleets/'+object.id}><img width="300" src={require('./'+object.image_name)} alt="..." /></a>
                  </div>
                  <div className="fleetDescription">
                    <div className="fleetText">
                      <a className="linkLayer" href={'/fleets/'+object.id}><h6 className="m-2">{object.name}</h6></a>
                      <p className="m-2">{object.description.substring(0, AUTHORIZED_DESCRIPTION_LENGTH)}...&nbsp;<a href={'/fleets/'+object.id}>Read more</a></p>

                    </div>
                    <div className= "bottomCard">
                      {object.subscription ?
                      <a className="btn btn-default categoryButton">{getStatus(object.subscription.status)}</a> :
                      <a className="btn btn-default categoryButton">Not subscribed</a>}
                    </div>
                  </div>
                </div>
              </div>
            )) : null
            }
            </div>
        </>
    );
};
export default FleetsPage;