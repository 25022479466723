import React            from "react";
import ReactDOM         from "react-dom/client";
import { Provider }     from "react-redux";

import { store }        from './Redux/store';
import App              from "./App";
import reportWebVitals  from "./reportWebVitals";
import api              from "./Utils/Axios";
import "./index.css";

api.defaults.withCredentials = true;
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
